//Default header for posting forms to API gateway
export const postConfigHeader = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    timeout: 8000
};

//Scroll to the first bad input section denoted by class "error-parent"
export const scrollToFirstError = (errors) => {
    const errorId = Object.keys(errors)[0];
    const problemElement = document.getElementById(errorId) ?? document.getElementsByName(errorId)?.[0];
    const errorParent = problemElement?.closest('.error-parent');
    // if(errorId) {
    //     console.debug('ERRORS', errors)
    //     console.debug('Error obj', errorId);
    //     console.debug('Problem and parent', problemElement, errorParent);
    // }
    errorParent?.scrollIntoView?.({ behavior: 'smooth' });
}

export const getElement = (id) => {
    return document.getElementById(id);
}

//* Return input element's SHOWN value
// useful for selects which include extra text we don't need to show to user on their confirmation page
export const getElementValue = (elementId) => {
    const element = document.getElementById(elementId);

    if (element?.tagName === 'SELECT')
        return element.selectedOptions[0]?.text ?? '';
    if (element?.type === 'text') {
        return element.value;
    }
    const elementNameQuery = document.querySelectorAll(`input[name="${elementId}"]:checked`);
    //Checkbox/radio (e.g. type of complaint)
    if (elementNameQuery.length > 0) {
        const concatStrings = Array.from(elementNameQuery)
            .map(checkbox => checkbox.labels ? checkbox.labels[0]?.innerText : '')
            .join(', ') ?? [];
        return concatStrings;
    }
    return '';
};