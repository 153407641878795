import React, {useContext, useState} from 'react';
import {FormattedMessage, IntlContext} from 'react-intl';
import { Field, useFormikContext, FieldArray } from "formik";
import { SelectFieldWithLabel, TextFieldWithLabel } from './FormFields';
import { getConstants } from '../constants';
import { sortArrayAlphabetically } from '../utils/miscUtils';

import MagnifiquePopup from './MagnifiquePopup';

export default function ReferenceNumberDetails(props) {
    const { values, setFieldValue } = useFormikContext();
	const { messages } = useContext(IntlContext);
    const constants = getConstants(messages);

	// Examples Popup stuff
	const [cvborPopup, setCvborPopup] = useState(false);

    let referenceTypes = constants["referenceTypes"];
	sortArrayAlphabetically(referenceTypes, 'label')

    const referenceNumberPlaceholders = constants["referenceNumberPlaceholders"];

    return (
		<>
			<section id="referenceNumberDetails" className="panel panel-default error-parent">
				<header className="panel-heading bold">
					<FormattedMessage id="examples-rfnmxmpl-reference" />
					<button type="button" style={{border: 'none', background: 'none'}} onClick={() => { setCvborPopup(true)}} className="glyphicon glyphicon-info-sign" />
				</header>

				{/* We don't really need this pop-up since the placeholders give the same information */}
				<MagnifiquePopup 
					id="cvborModal" 
					trigger={cvborPopup} 
					setTrigger={setCvborPopup} 
					cancelButton={messages['button.close']}
					hideContinue={true}
					// setGo={setGoahead} 
					// setBack={setBackdown}
					header={<FormattedMessage id="reference-number-examples" />} 
					body={<>
						{ referenceTypes.map((ref, index) => {
							return (
								<p key={`refExample-${index}`}>{ref.label} - {referenceNumberPlaceholders[ref.value]}</p>
							)

						})}
					</>} 
				/>

				<div className="panel-body" id="referenceItem">
					<p>
						<FormattedMessage id="identifying-supplemental-documentation" />
					</p>
					<FieldArray name="referenceItem">
						{({insert, remove, push, pop}) => (
						<>
							{values["referenceItem"].map((referenceItem, index) => { 
								const referenceType = "referenceItem["+index+"].referenceType"; 
								const referenceTypeLabel = "referenceItem["+index+"].referenceTypeLabel"; 
								const referenceNumber = "referenceItem["+index+"].referenceNumber"; 
								const referenceTypePlaceholder = referenceNumberPlaceholders[referenceItem.referenceType];
								const readOnly = !values["referenceItem"][index]["referenceType"];

								return (
									<section className="panel panel-default" key={`refNumber-${index}`}>
										<header className="panel-heading">
											{messages['reference']} {index + 1}
											<button type="button" className="glyphicon glyphicon-remove close-button" 
												title={`${messages['delete-reference']} ${index + 1}`}
												aria-label={`${messages['delete-reference']} ${index + 1}`} 
												onClick={() => remove(index)}
											/>
										</header>
										<div className="panel-body">
											<div className="row">
												<SelectFieldWithLabel id={referenceType} name={referenceType} label={messages["CIPS.i18n-referenceType-label"]}  
												wrapperStyle={{}} 
												wrapperClassName={"form-group col-md-6 col-sm-12 col-xs-12"} 
												fieldClassName={{}} labelClassName="control-label" 
												options={[ 
													{ value:"", label: messages["CIPS.i18n-referenceType"]}, 
													...referenceTypes 
												]} 
												onChange={(e) => {
													setFieldValue(referenceType, e.target.value)
													//set hidden field to the visible label, makes confirmation page generation easier when form is submitted
													const selectedLabel = e.target.options[e.target.selectedIndex].text;
													setFieldValue(referenceTypeLabel, selectedLabel)
													}
												}
											/>
											<TextFieldWithLabel id={referenceNumber} name={referenceNumber} label={messages["number-reference"]} 
												wrapperStyle={{}} 
												wrapperClassName="form-group col-md-6 col-sm-12 col-xs-12" 
												placeholder={referenceTypePlaceholder}
												readOnly={readOnly}
											/>
											<Field type="hidden" id={referenceTypeLabel} name={referenceTypeLabel} />
											</div>
										</div>
									</section>
								)
							})}
							<button type="button" id="addRefItem" className="add-button" title={messages['add-reference']} 
								onClick={() => push({ referenceType: "" , referenceNumber: "" })}
							>
								{messages['add-reference']}
							</button>
						</>
						)}
					</FieldArray>
				</div>
				<div style={{padding: '15px'}}>
					<label id="serviceProviderLabel" htmlFor="serviceProvider" className="control-label">
						<span className="field-name">
							<FormattedMessage id="service-provider-if-applicable" />
						</span>
					</label>
					<Field id="serviceProvider" name="serviceProvider" className="form-control full-width" />
				</div>
			</section>
		</>
    )
}