import React, {useContext, useEffect} from 'react'
import { IntlContext } from 'react-intl';
import { useFormikContext } from "formik";
import { SelectFieldWithLabel, TextFieldWithLabel } from './FormFields';
import { getConstants } from '../constants';

export default function ProvinceStateDropdown({countryFieldId, provinceId, stateId, provinceStateComplaintId, wrapperClass, ...props}) {
    const { values, setFieldValue } = useFormikContext();
	const { messages } = useContext(IntlContext);
    const constants = getConstants(messages);

	const provinceComplaint = constants["provinceComplaint"];
    const stateComplaint = constants["stateComplaint"];

	const CANADA = constants['countriesForValidation']['Canada'];
	const US = constants['countriesForValidation']['United States'];

	// Reset province/state fields when country changes
	// useEffect(() => {
	// 	setFieldValue(provinceId, '');
    //     setFieldValue(stateId, '');
    //     setFieldValue(provinceStateComplaintId, '');
	// }, [values[countryFieldId]])

    return (
    	<>
            {values[countryFieldId] ?
				<>
					{values[countryFieldId] === "Canada [country=CA]" ?
						<SelectFieldWithLabel data-testid="province-select" id={provinceId} name={provinceId} wrapperStyle={{}} fieldClassName={{}} wrapperClassName={wrapperClass ? wrapperClass : "form-group col-xs-12 col-md-6"} labelClassName="control-label" label={messages["CIPS.i18n-province-state-label"]} options={[ {value:"", label: messages["CIPS.i18n-province-select"]} , ...provinceComplaint ]} />
						: 
						values[countryFieldId] === "United States [country=US]" ?
							<SelectFieldWithLabel id={stateId} name={stateId} wrapperStyle={{}} fieldClassName={{}} wrapperClassName={wrapperClass ? wrapperClass : "form-group col-xs-12 col-md-6"} labelClassName="control-label" label={messages["CIPS.i18n-province-state-label"]} options={[ {value:"", label: messages["CIPS.i18n-state-select"]} , ...stateComplaint ]} />
							:
							<TextFieldWithLabel id={provinceStateComplaintId} name={provinceStateComplaintId} wrapperClassName={wrapperClass ? wrapperClass : "form-group col-xs-12 col-md-6"} wrapperStyle={{}} required={false} label={messages["CIPS.i18n-province-state-label"]} />
					}
				</>
				:
				<TextFieldWithLabel id={provinceStateComplaintId} name={provinceStateComplaintId} wrapperClassName={wrapperClass ? wrapperClass : "form-group col-xs-12 col-md-6"} wrapperStyle={{}} required={false} label={messages["CIPS.i18n-province-state-label"]} />
    		}
    	</>
    )
}
