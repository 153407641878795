export const currentLang = () => {
	if ("lang" in currentLangParam() && document?.documentElement?.lang === undefined) {
		document.documentElement.lang = currentLangParam().lang;
	}
	let currentLang = document?.documentElement?.lang ?? 'en'; // en/fr
	
	return currentLang;
}

export const oppositeLang = () => {
	return currentLang() === 'en' ? 'fr' : 'en';
}

export const currentLangParam = () => {
	let langParam = {};
	let urlParams = new URLSearchParams(document.location.search);
	if (urlParams.has('lang')) {
		langParam = {lang: urlParams.get('lang')};
	}
	return langParam;
}

export const currentLongLang = () => {
	return currentLang() === "fr" ? "fra" : "eng";
}

export const getOtherLangHref = () => {
	const url = new URL(document.location); 
	const current = currentLang(); 
	const otherLang = current === "en" ? "fr" : "en"; 


	url.searchParams.set("lang", otherLang);

	return url.toString();
};

export const sortArrayAlphabetically = (array, prop, reverse) => {
	array.sort((a, b) => {
		if (a[prop] < b[prop]) {
			return reverse ? 1: -1;
		}
		if (a[prop] > b[prop]) {
			return reverse? -1: 1;
		}
		return 0;
	});
	return array;
}